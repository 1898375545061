const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 获取权限列表接口文档地址：http://192.168.0.15:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/resource-controller/getPermissionsUsingPOST
const web_common_source_getPermissions = (pParameter) => {
  if (!pParameter) pParameter = {};
  const userId = pParameter.userId || '';
  const p = {
    method: 'post',
    urlSuffix: '/web/common/source/getPermissions',
    data: {
      userId,
    }
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      const permissionList = data.permissionList;
      if (!permissionList) {
        const msg = 'web_common_source_getPermissions parameter error, code=101';
        console.error(msg);
        return Q.reject({msg: msg});
      }
      if (!_.isArray(permissionList)) {
        const msg = 'web_common_source_getPermissions parameter error, code=102';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    });
};
module.exports = web_common_source_getPermissions;

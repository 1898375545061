const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`)

/**
 * 首页底部推荐图标  http://219.128.52.2:9999/doc.html#/yinzhilv_travel/%E5%95%86%E5%9F%8E%E9%A6%96%E9%A1%B5/getBottomTagListUsingPOST
 */
export default function getDetail(data) {
    var pParameter = {};
    pParameter.method = "POST";
    pParameter.urlSuffix = "web/common/TourismMall/bottomTagList";
    pParameter.data = data;
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
};

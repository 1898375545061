const wrap_cache = require(`nllib/src/promise-helper/wrap_cache`);
const __web_common_source_getPermissions = require(`@/lib/data-service/default/web_common_source_getPermissions`);

const web_common_source_getPermissions = function (pParameter) {
  if (!pParameter) {
    pParameter = {};
  }

  const clear_cache = pParameter.clear_cache;

  const fn = __web_common_source_getPermissions;

  return wrap_cache({
    fn: fn,
    clear_cache: clear_cache
  });
};

module.exports = web_common_source_getPermissions;

const wrap_cache = require(`nllib/src/promise-helper/wrap_cache`);
const get_operation_guide_title_two = require(`@/lib/data-service/yinzhilv-temporary-storage-system/get_operation_guide_title_2`);

const get_operation_guide_title_two_common = function (pParameter) {
  if (!pParameter) {
    pParameter = {};
  }

  const clear_cache = pParameter.clear_cache;

  const fn = get_operation_guide_title_two;

  return wrap_cache({
    fn: fn,
    clear_cache: clear_cache
  });
};

module.exports = get_operation_guide_title_two_common;

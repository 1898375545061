const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：http://192.168.0.15:8080/doc.html#/所有接口/login-controller/logoutUsingPOST
const logout = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: 'logout',
    data: {}
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = logout;

// get_insurance_enumeration 保险类型枚举
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
const get_operation_guide_title_two = (pParameter) => {
  let params = {};
  if (!pParameter) {
    pParameter = '';
  }
  let query = {
    query: `query {
      allYinzhilvHelpCenterLists(sortBy: menuLevelFirstIndex_ASC) {
        id,
        menuLevelFirstIndex,
        menuLevelFirstName,
        menuLevelSecondName,
        menuLevelSecondIndex,
        title,
        content
      }
    }`
  };
  params.method = 'POST';
  params.urlSuffix = '/admin/api';
  params.data = query;

  return __request(params);

};

module.exports = get_operation_guide_title_two;